import posthog from "posthog-js";
import * as Sentry from "@sentry/vue";
import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";

import "bootstrap/dist/css/bootstrap.css";
import "@/assets/css/style.css";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://fee8f8e568574f58aaa86c4c68e75255@o4504216596381696.ingest.sentry.io/4505545479094272",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

posthog.init("phc_OlbgBpE1EUwtct01e8cVqWpXTwwbqM5eWPIBcTemnoZ", {
  api_host: "https://app.posthog.com",
});

import "bootstrap/dist/js/bootstrap.js";

app.use(router);

app.mount("#app");
