<template>
  <main class="px-3">
    <h1>Get in touch.</h1>
    <p class="lead">
      <a
        href="mailto:hello@foundability.ca"
        style="color: #fff; text-decoration: none"
      >
        hello(a)foundability.ca
      </a>
    </p>
  </main>
</template>
